import { Link } from 'react-router-dom'
import './Credit.css'
import { Locale } from '../../components/Locale'
import LanguageOption from '../../components/LanguageOption'

export default function Credit() {
  return (
    <>
      <div className="Credit">
        <LanguageOption />
        <h2>
          <Locale
            en="Choose Your Option"
            zh_cn="选择您的选项"
            zh_hk="選擇您的選項"
          />
        </h2>
        <Link to="/app/package" className="option">
          <h3 className="option-title">
            <Locale en="Top-Up Credit" zh_cn="充值点数" zh_hk="充值點數" />
          </h3>
          <p className="option-description">
            <Locale
              en="Best value for experiment and exploration!"
              zh_cn="适合实验和探索！"
              zh_hk="適合實驗和探索！"
            />
          </p>
        </Link>
        <Link to="/app/subscription" className="option">
          <h3 className="option-title">
            <Locale en="Subscription Plan" zh_cn="包月计划" zh_hk="月費計劃" />
          </h3>
          <p className="option-description">
            <Locale en="Coming soon!" zh_cn="即將推出！" zh_hk="即將推出！" />
          </p>
        </Link>
      </div>
    </>
  )
}
