import LanguageOption from '../../components/LanguageOption'
import { Locale } from '../../components/Locale'
import { useGet } from '../../hooks/useGet'
import {
  getTopUpPackages,
  GetTopUpPackagesOutput,
  topUpCredit,
} from '../../sdk/credit'
import './Package.css'

export default function Package() {
  // FIXME: replace localhost with actual domain
  async function requestTopUp(
    topUpPackage: GetTopUpPackagesOutput['packages'][number],
  ) {
    let json = await topUpCredit({
      body: {
        package_id: topUpPackage.package_id,
      },
    })

    // redirect to the payment page
    window.location.href = json.url
  }

  const topUpPackages = useGet({ packages: [] }, () => getTopUpPackages({}))

  return (
    <div className="Package">
      <LanguageOption />
      <h2>
        <Locale
          en="Choose Your Package"
          zh_cn="选择您的套餐"
          zh_hk="選擇您的套餐"
        />
      </h2>
      <div className="package-container"></div>
      {topUpPackages.state.packages?.map(topUpPackage => {
        let unitPrice = topUpPackage.fee / topUpPackage.credits
        return (
          <div
            className="package-option"
            onClick={() => requestTopUp(topUpPackage)}
          >
            <h3 className="package-option-title">
              <Locale
                en={topUpPackage.title_en}
                zh_cn={topUpPackage.title_zh_cn}
                zh_hk={topUpPackage.title_zh_hk}
              />
            </h3>
            <div className="package-option-description">
              <div>
                <Locale
                  en={`${topUpPackage.fee} for ${topUpPackage.credits} credits`}
                  zh_cn={`${topUpPackage.fee} 元充值 ${topUpPackage.credits} 点数`}
                  zh_hk={`${topUpPackage.fee} 元充值 ${topUpPackage.credits} 點數`}
                />
              </div>
              <div>(${unitPrice.toFixed(2)} per credit)</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
