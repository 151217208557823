import { LocaleVariant } from './Locale'
import { Role } from '../hooks/useRole'

export type MenuItem = {
  href: string
  role: 'guest' | 'user' | 'all'
  icon: string
  label: LocaleVariant
}

export let menuItems: MenuItem[] = [
  {
    href: '/app/gallery',
    role: 'all',
    icon: 'fa-solid fa-images',
    label: {
      en: 'Explore',
      zh_cn: '探索',
      zh_hk: '探索',
    },
  },
  {
    href: '/app/editor',
    role: 'all',
    icon: 'fa-solid fa-pencil',
    label: {
      en: 'Editor',
      zh_cn: '编辑器',
      zh_hk: '編輯器',
    },
  },
  {
    href: '/app/history',
    role: 'user',
    icon: 'fa-solid fa-cube',
    label: {
      en: 'My Scenes',
      zh_cn: '我的场景',
      zh_hk: '我的場景',
    },
  },
  {
    href: '/app/credit',
    role: 'all',
    icon: 'fa-solid fa-crown',
    label: {
      en: 'Credit',
      zh_cn: '会员点数',
      zh_hk: '會員點數',
    },
  },
  {
    href: '/app/login',
    role: 'guest',
    icon: 'fa-solid fa-user',
    label: {
      en: 'Login',
      zh_cn: '登录',
      zh_hk: '登入',
    },
  },
  {
    href: '/app/profile',
    role: 'user',
    icon: 'fa-solid fa-user',
    label: {
      en: 'Profile',
      zh_cn: '个人资料',
      zh_hk: '個人資料',
    },
  },
  {
    href: '/',
    role: 'all',
    icon: 'fa-solid fa-house',
    label: {
      en: 'Home',
      zh_cn: '主页',
      zh_hk: '主頁',
    },
  },
]

export function getMenuItems(props: { role: Role }) {
  return menuItems.filter(
    item => item.role === 'all' || item.role === props.role,
  )
}
