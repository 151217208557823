import React, { useState, useEffect } from 'react'
import { GetUserProfileOutput, getUserProfile } from '../sdk/user'
import { useToken } from '../hooks/useToken'
import { Link, useNavigate } from 'react-router-dom'
import { Locale } from '../components/Locale'
import LanguageOption from '../components/LanguageOption'
import './Profile.css'

function Profile() {
  const { token, logout } = useToken()

  const [profile, setProfile] = useState<GetUserProfileOutput | null>(null)

  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      getUserProfile({}).then(setProfile)
    }
  }, [token])

  useEffect(() => {
    if (!token) {
      navigate('/app/login')
    }
  }, [token])

  function renderBody() {
    if (!token) {
      return (
        <>
          <p>
            <Locale
              en="This page is only for logged in users."
              zh_cn="此页面仅限已登录用户访问。"
              zh_hk="此頁面僅限已登入用戶訪問。"
            />
          </p>
          <Link to="/app/login">
            <button>
              <Locale
                en="Login / Register now"
                zh_cn="立即登录 / 注册"
                zh_hk="立即登入 / 註冊"
              />
            </button>
          </Link>
        </>
      )
    }
    if (!profile) {
      return (
        <>
          <Locale en="Loading..." zh_cn="加载中..." zh_hk="加載中..." />
        </>
      )
    }
    return (
      <>
        <h1>
          <Locale en="Welcome, " zh_cn="欢迎，" zh_hk="歡迎，" />
          {profile.username || profile.email}
        </h1>
        <p className="credit-info">
          <Locale en="Credit" zh_cn="会员点数" zh_hk="會員點數" />:{' '}
          {profile.credit}{' '}
          <Link to="/app/package">
            <button className="top-up-button">
              <Locale en="Top Up" zh_cn="充值" zh_hk="充值" />
            </button>
          </Link>
        </p>
        <Link to="/app/history">
          <button>
            <Locale
              en="Go to My Scenes"
              zh_cn="前往我的场景"
              zh_hk="前往我的場景"
            />
          </button>
        </Link>

        <button onClick={logout}>
          <Locale en="Logout" zh_cn="登出" zh_hk="登出" />
        </button>
      </>
    )
  }

  return (
    <>
      <div className="body Profile">
        <LanguageOption />
        <div className="reg-container main">{renderBody()}</div>
      </div>
    </>
  )
}

export default Profile
