import React, { Component, useState } from 'react'
//import HKSTP_Night from '../images/Feelings_HKSTP_Night.jpeg'
//import HKSTP_Morning from '../images/Feelings_HKSTP.jpg'
import {
  generateSceneByGuest,
  getRandomSceneByGuest,
  waitSceneMusic,
  bookmarkScene,
  unBookmarkScene,
} from '../sdk/scene'
import './Home.css'
// @ts-ignore
import { Pannellum } from 'pannellum-react'
import { later } from '@beenotung/tslib/async/wait'
import { getNextTime } from '../sdk/rate-limit'
import { SECOND } from '@beenotung/tslib/time'
import { GetSkyboxStyleListOutput, getSkyboxStyleList } from '../sdk/skybox'
import { getSunoStyleList, GetSunoStyleListOutput } from '../sdk/music'
import { Lang, Locale, translate, useLang } from '../components/Locale'
import { Music } from '../components/Music'
import Nav from '../components/nav'

type Props = {
  lang: Lang
}

type State = {
  inputMode: 'image' | 'music'
  scene_id: number
  image_url: string
  music_url: string | null
  error: string | undefined
  image_style: string
  music_style: string
  image_prompt: string
  music_prompt: string
  is_generating_image: boolean
  is_generating_music: boolean
  is_downloading: boolean
  hasBookmarked: boolean
  next_time: number
  skybox_styles: GetSkyboxStyleListOutput['styles']
  suno_styles: GetSunoStyleListOutput['styles']
}

class Scene extends Component<Props, State> {
  state: State = {
    inputMode: 'image',
    scene_id: 0,
    image_url: '',
    music_url: '',
    error: '',
    image_style: '',
    music_style: '',
    image_prompt: '',
    music_prompt: '',
    is_generating_image: false,
    is_generating_music: false,
    is_downloading: false,
    hasBookmarked: false,
    next_time: 0,
    skybox_styles: [],
    suno_styles: [],
  }
  password = ''

  componentDidMount(): void {
    getSkyboxStyleList({}).then(json => {
      this.update({ skybox_styles: json.styles })
    })
    getSunoStyleList({}).then(json => {
      this.update({ suno_styles: json.styles })
    })
    this.loadScene()
    //this.getStyles()
    getNextTime({}).then(json => {
      this.setNextTime(json.next_time)
    })
  }

  bookmarkScene = async (scene_id: number) => {
    await bookmarkScene({ params: { scene_id } })
    this.setState({ hasBookmarked: true })
  }

  unBookmarkScene = async (scene_id: number) => {
    await unBookmarkScene({ params: { scene_id } })
    this.setState({ hasBookmarked: false })
  }

  loadScene = async () => {
    try {
      let json = await getRandomSceneByGuest({})
      this.update({
        image_url: json.image_url,
        music_url: json.music_url,
        scene_id: json.scene_id,
        hasBookmarked: json.has_bookmarked,
        error: json.error,
      })
      console.log(json)
    } catch (error) {
      this.update({ error: String(error) })
    }
  }

  generate = async () => {
    try {
      let image_style = this.state.image_style
      let music_style = this.state.music_style
      if (!image_style) {
        throw new Error('Please select an image style')
      }
      let image_prompt = this.state.image_prompt
      let music_prompt = this.state.music_prompt
      if (!image_prompt) {
        throw new Error('Please input an image prompt')
      }
      this.update({
        is_generating_image: true,
        is_generating_music: music_prompt.length > 0 && music_style.length > 0,
      })
      let json = await generateSceneByGuest({
        body: {
          image: {
            prompt: image_prompt,
            style: image_style,
          },
          music: {
            prompt: music_prompt,
            style: music_style,
          },
        },
      })
      if (json.error) {
        this.update({
          error: json.error,
          is_generating_image: false,
          is_generating_music: false,
        })
        return
      }
      this.setNextTime(json.next_time)
      if (!json.scene) {
        this.update({
          error: '',
          is_generating_image: false,
          is_generating_music: false,
        })
        return
      }
      let scene = json.scene
      this.update({
        image_url: scene.image_url,
        music_url: scene.music_url,
        error: json.error,
        is_generating_image: false,
      })
      let { scene_id } = scene
      while (true) {
        let json = await waitSceneMusic({ params: { scene_id } })
        if (!json.music_url) {
          await later(1000)
          continue
        }
        this.update({
          music_url: json.music_url,
          is_generating_music: false,
        })
        break
      }
    } catch (error) {
      console.log('cannot generate scene:', error)
      this.update({
        error: String(error),
        is_generating_image: false,
        is_generating_music: false,
      })
    }
  }

  update(state: Partial<State>) {
    if (state.error) {
      // TODO use sweetalert
      alert(state.error)
    }
    this.setState(state as State)
  }

  nextTimeInterval?: ReturnType<typeof setInterval>

  setNextTime(next_time: number) {
    this.update({ next_time })
    if (this.nextTimeInterval) {
      clearInterval(this.nextTimeInterval)
      this.nextTimeInterval = undefined
    }
    if (next_time <= Date.now()) return
    this.nextTimeInterval = setInterval(() => {
      this.forceUpdate()
    }, 100)
  }

  renderGenerateButton() {
    let interval = this.state.next_time - Date.now()
    let disabled = this.state.is_generating_image || interval > 0
    return (
      <button
        disabled={disabled}
        onClick={this.generate}
        className="prompt-button"
      >
        {this.state.is_generating_image ? (
          <Locale
            en="Generating Scene..."
            zh_cn="生成场景中..."
            zh_hk="生成場景中..."
          />
        ) : this.state.is_generating_music ? (
          <Locale
            en="Generating Music..."
            zh_cn="生成音乐中..."
            zh_hk="生成音樂中..."
          />
        ) : interval > 0 ? (
          <Locale
            en={`Generate (wait ${(interval / SECOND).toFixed(1)} seconds)`}
            zh_cn={`生成中 (等待 ${(interval / SECOND).toFixed(1)} 秒)`}
            zh_hk={`生成中 (等待 ${(interval / SECOND).toFixed(1)} 秒)`}
          />
        ) : (
          <Locale en="Generate" zh_cn="生成" zh_hk="生成" />
        )}
      </button>
    )
  }

  handleShare = async () => {
    const scene_id = this.state.scene_id
    const url = `${window.location.origin}/app/editor/${scene_id}`
    try {
      await this.shareToApp(url)
    } catch (error) {
      let message = String(error)
      if (message.toLowerCase().includes('cancellation')) {
        return
      }
      this.showNotification(message)
      await this.copyToClipboard(url).catch(error => {
        alert(String(error))
      })
    }
  }

  handleDownload = async () => {
    this.update({
      is_downloading: true,
    })
    const scene_id = this.state.scene_id
    const response = await fetch(this.state.image_url)
    const blob = await response.blob()

    const a = document.createElement('a')
    a.href = URL.createObjectURL(blob)

    const img = new Image()
    img.src = a.href
    img.addEventListener('load', () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')

      if (ctx) {
        ctx.drawImage(img, 0, 0)

        canvas.toBlob(newBlob => {
          if (newBlob) {
            const url = URL.createObjectURL(newBlob)

            const link = document.createElement('a')
            link.href = url
            link.download = 'scene_image.png'
            link.click()

            URL.revokeObjectURL(url)
          }
        }, 'image/png')
      }
    })
    this.update({
      is_downloading: false,
    })
  }

  async shareToApp(url: string) {
    await navigator.share({
      title: 'Share Scene',
      text: 'Check out this scene:',
      url,
    })
  }

  async copyToClipboard(url: string) {
    const { lang } = this.props
    try {
      await navigator.clipboard.writeText(url)
    } catch (error) {
      let supported = document.execCommand('copy', false, url)
      if (!supported) {
        throw new Error('Clipboard copy not supported')
      }
    }
    this.showNotification(
      translate(lang, {
        en: 'Share link copied to clipboard',
        zh_cn: '分享链接已复制到剪贴板',
        zh_hk: '分享鏈接已複製到剪貼板',
      }),
    )
  }

  showNotification = (message: string) => {
    const notification = document.createElement('div')
    notification.classList.add('notification')
    notification.textContent = message
    document.body.appendChild(notification)

    setTimeout(() => {
      notification.remove()
    }, 3000)
  }

  render() {
    const { lang } = this.props
    const music_url = this.state.music_url || ''
    let loadingPopup = (
      <div
        className="loadingPopup"
        hidden={
          !this.state.is_generating_image && !this.state.is_generating_music
        }
      >
        <div className="loadingPopupContent">
          <div className="loadingPopupText">
            <Locale
              en="We are in beta stage, so loading might take some time. Thank you for your patience."
              zh_cn="我们正处于测试阶段，因此加载可能需要一些时间。感谢您的耐心等待..."
              zh_hk="我哋喺測試階段，所以載入可能需要一段時間，多謝你嘅耐性..."
            />
          </div>
        </div>
      </div>
    )

    let downloadingPopup = (
      <div className="downloadingPopup" hidden={!this.state.is_downloading}>
        <div className="downloadingPopupContent">
          <div className="downloadingPopupText">
            <Locale
              en="Downloading scene... Please wait"
              zh_cn="正在下载场景... 请稍候"
              zh_hk="下載緊嘅場景... 請等下"
            />
          </div>
        </div>
      </div>
    )

    let main = (
      <>
        <div className="header">
          <Music url={music_url} />
          <Pannellum
            width="100vw"
            height="100vh"
            image={this.state.image_url}
            yaw={300}
            hfov={110}
            autoLoad
            autoRotate={-5}
            compass={true}
            showZoomCtrl={false}
            mouseZoom={false}
            onLoad={() => {
              console.log('panorama loaded')
            }}
          >
            <Pannellum.Hotspot
              type="custom"
              pitch={-10}
              yaw={-120}
              handleClick={(evt: MouseEvent, args: any) => this.loadScene()}
            />
          </Pannellum>

          <div className="container" data-input-mode={this.state.inputMode}>
            {loadingPopup}
            {downloadingPopup}
            <div className="buttonAndIconContainer">
              <div className="ToggleButtonsContainer">
                <button
                  className="ToggleButtonImage"
                  onClick={() => this.setState({ inputMode: 'image' })}
                >
                  {translate(lang, {
                    en: 'Image',
                    zh_cn: '图片',
                    zh_hk: '圖片',
                  })}
                </button>
                <button
                  className="ToggleButtonMusic"
                  onClick={() => this.setState({ inputMode: 'music' })}
                >
                  {translate(lang, {
                    en: 'Music',
                    zh_cn: '音乐',
                    zh_hk: '音樂',
                  })}
                </button>
              </div>
              <div className="iconContainer">
                {this.state.hasBookmarked ? (
                  <button
                    onClick={() => this.unBookmarkScene(this.state.scene_id)}
                    title={translate(lang, {
                      en: 'unbookmark the scene',
                      zh_cn: '取消此场景的书签',
                      zh_hk: '取消呢個場景嘅書籤',
                    })}
                  >
                    <i className="fa-solid fa-bookmark"></i>
                  </button>
                ) : (
                  <button
                    onClick={() => this.bookmarkScene(this.state.scene_id)}
                    title={translate(lang, {
                      en: 'bookmark scene',
                      zh_cn: '将此场景添加为书签',
                      zh_hk: '將呢個場景加入書籤',
                    })}
                  >
                    <i className="fa-regular fa-bookmark"></i>
                  </button>
                )}
                <button
                  onClick={this.handleShare}
                  title={translate(lang, {
                    en: 'share scene',
                    zh_cn: '分享这个场景',
                    zh_hk: '分享呢個場景',
                  })}
                >
                  <i className="fa-solid fa-share"></i>
                </button>
                <button
                  onClick={this.handleDownload}
                  title={translate(lang, {
                    en: 'download scene',
                    zh_cn: '下載這個場景',
                    zh_hk: '下載呢個場景',
                  })}
                >
                  <i className="fa-solid fa-download"></i>
                </button>
                <button
                  onClick={this.loadScene}
                  title={translate(lang, {
                    en: 'change scene',
                    zh_cn: '改變這個場景',
                    zh_hk: '改變呢個場景',
                  })}
                >
                  <i className="fa-solid fa-dice"></i>
                </button>
              </div>
            </div>

            {/* Upper section for image prompt and style */}
            <div data-input-section="image" className="section">
              <input
                type="text"
                value={this.state.image_prompt}
                onChange={e => this.update({ image_prompt: e.target.value })}
                placeholder={translate(lang, {
                  en: 'Enter Image prompt here: ',
                  zh_cn: '输入图片提示: ',
                  zh_hk: '輸入圖片提示: ',
                })}
                className="input-text"
                autoComplete="scene_prompt"
              />
              <div className="input-container">
                <input
                  placeholder={translate(lang, {
                    en: 'Select an image style:',
                    zh_cn: '选择图像样式：',
                    zh_hk: '選擇圖像風格：',
                  })}
                  className="input-text"
                  list="skyboxStyleList"
                  value={this.state.image_style}
                  onChange={e => this.update({ image_style: e.target.value })}
                />
                <i className="fa-solid fa-caret-down dropdown-icon"></i>
              </div>

              <datalist id="skyboxStyleList">
                {this.state.skybox_styles.map(style => (
                  <option key={style.id}>{style.name}</option>
                ))}
              </datalist>
            </div>
            {/* Lower section for music prompt and style */}
            <div data-input-section="music" className="section">
              <input
                type="text"
                value={this.state.music_prompt}
                onChange={e => this.update({ music_prompt: e.target.value })}
                placeholder={translate(lang, {
                  en: 'Enter Music prompt (optional):',
                  zh_cn: '输入音乐提示（可选）：',
                  zh_hk: '輸入音樂提示（可選）：',
                })}
                className="input-text"
                autoComplete="music_prompt"
              />
              <div className="input-container">
                <input
                  placeholder={translate(lang, {
                    en: 'Prompt a music style (optional):',
                    zh_cn: '提示音乐风格（可选）：',
                    zh_hk: '提示音樂風格（可選）：',
                  })}
                  className="input-text"
                  list="sunoStyleList"
                  value={this.state.music_style}
                  onChange={e => this.update({ music_style: e.target.value })}
                />
                <i className="fa-solid fa-caret-down dropdown-icon"></i>
              </div>

              <datalist id="sunoStyleList">
                {this.state.suno_styles.map(style => (
                  <option key={style}>{style}</option>
                ))}
              </datalist>
            </div>
            {this.renderGenerateButton()}
          </div>
        </div>
      </>
    )
    return (
      <>
        <Nav hasMusic={!!music_url} scene_id={this.state.scene_id} />
        <div className="h-screen flex">{main}</div>
      </>
    )
  }
}

function Home() {
  const { lang } = useLang()
  return <Scene lang={lang} />
}

export default Home
