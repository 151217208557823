import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './nav.css'
import {
  langOptions,
  translate,
  Locale,
  LocaleVariant,
  useLang,
} from './Locale'
import { VolumeSlider } from './Music'
import { useGlobalClick } from '../hooks/useGlobalClick'

interface NavItem {
  label: LocaleVariant
  to: string
  icon: string
}

const Nav = (props: { hasMusic: boolean; scene_id: number }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState(false)
  const { lang, setLang } = useLang()

  const navItems: NavItem[] = [
    // {
    //   label: {
    //     en: 'Home',
    //     zh_cn: '主页',
    //     zh_hk: '主頁',
    //   },
    //   to: '/',
    //   icon: 'fa-solid fa-house',
    // },
    {
      label: {
        en: 'Explore',
        zh_cn: '探索',
        zh_hk: '探索',
      },
      to: '/app/gallery',
      icon: 'fa-solid fa-images',
    },
    {
      label: {
        en: 'Creator',
        zh_cn: '工作室',
        zh_hk: '工作室',
      },
      to: props.scene_id ? `/app/editor/${props.scene_id}` : '/app/editor',
      icon: 'fa-solid fa-pencil',
    },
  ]

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen)
    if (isLanguageDropdownOpen) {
      setLanguageDropdownOpen(false)
    }
  }

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!isLanguageDropdownOpen)
  }

  const handleCompanyWebsiteClick = () => {
    window.location.href = 'https://feelingss.ai/'
  }

  useGlobalClick(target => {
    if (target.closest('.optionsButton') || target.closest('.dropdown-menu')) {
      return
    }
    setDropdownOpen(false)
    setLanguageDropdownOpen(false)
  })

  return (
    <div className="nav-container">
      <div className="innerContainer">
        <div className="logo-and-title">
          <img src="/images/feelings.png" alt="Feelings" className="nav-logo" />
          <h1 className="nav-title">Feelings AI</h1>
        </div>
        <VolumeSlider
          containerClassName="vol-container"
          inputClassName="volume-slider"
        />

        <nav className="nav-bar">
          {navItems.map(item => (
            <Link
              key={item.to}
              className={`nav-item ${
                location.pathname === item.to ? 'nav-item-selected' : ''
              }`}
              to={item.to}
            >
              <span className="label">
                <Locale {...item.label} />
              </span>
              <i className={item.icon}></i>
            </Link>
          ))}
        </nav>

        <div style={{ position: 'relative' }}>
          <button className="optionsButton" onClick={toggleDropdown}>
            <i className="fa-solid fa-bars"></i>
          </button>
          {isDropdownOpen && (
            <div className="dropdown-menu" style={{ position: 'absolute' }}>
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0',
                  cursor: 'pointer',
                }}
                onClick={handleCompanyWebsiteClick}
              >
                <img
                  style={{
                    marginRight: '3px',
                  }}
                  src="/images/website-logo.webp"
                  title="Feelings Group"
                />
              </button>
              <div className="dropdown-separator"></div>
              <button onClick={() => navigate('/app/login')}>
                {translate(lang, {
                  en: 'Login',
                  zh_cn: '登录',
                  zh_hk: '登入',
                })}
              </button>
              <button onClick={() => navigate('/app/login')}>
                {translate(lang, {
                  en: 'Register',
                  zh_cn: '注册',
                  zh_hk: '註冊',
                })}
              </button>
              <div className="dropdown-separator"></div>
              <button onClick={toggleLanguageDropdown}>
                <i className="fa-solid fa-globe"></i>
                {translate(lang, {
                  en: 'Language',
                  zh_cn: '语言',
                  zh_hk: '語言',
                })}
              </button>
              {isLanguageDropdownOpen && (
                <div
                  className="lang-dropdown"
                  style={{ position: 'absolute', top: '150px', left: '0' }}
                >
                  {langOptions.map(option => (
                    <button onClick={() => setLang(option.lang)}>
                      {option.label}
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Nav
